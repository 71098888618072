.display-email {
  font-size: 1.15rem;
}

.notification-text {
  font-size: 1.45rem;
}

hr {
  width: 90%;
}

.sap-sub-image {
  max-height: 18rem;
}

@media only screen and (min-width: 814px){
  .notification-text {
    font-size: 2rem;
  }
  .display-email {
    font-size: 1.85rem;
  }
  hr {
    width: 98%;
  }
}

@media only screen and (min-width: 1200px){
  .notification-text {
    font-size: 2.2rem;
  }
  .display-email {
    font-size: 2.15rem;
  }
  hr {
    width: 80%;
  }
}

@media screen and (max-width: 400px){
  .subscription-text{
    font-size: 0.7rem;
  }
  .service-content-sm {
    height: 38vw;
    overflow: scroll;
  }
  .service-content-md {
    height: 50vw;
    overflow: scroll;
  }
  .subscription-box{
    height: 0.7rem;
  }
}

@media screen and (max-width: 480px) {
  .service-content-sm {
    height: 50vw;
    overflow: scroll;
  }
  .service-content-md {
    height: 60vw;
    overflow: scroll;
  }
  .notification-text {
    font-size: 1.25rem;
  }
  .subscription-email-text{
    font-size: 0.8rem;
  }
  .subscription-text{
    font-size: 0.8rem;
  }
  .subscription-box{
    height: 0.8rem;
  }
}


