@font-face {
  font-family: '72';
  src: url("../assets/font/72-Black-full.woff2") format('woff2');
}
@font-face {
  font-family: '72';
  src: url("../assets/font/72-Bold-full.woff2") format('woff2');
}
@font-face {
  font-family: '72';
  src: url("../assets/font/72-BoldItalic-full.woff2") format('woff2');
}
@font-face {
  font-family: '72';
  src: url("../assets/font/72-Condensed-full.woff2") format('woff2');
}
@font-face {
  font-family: '72';
  src: url("../assets/font/72-CondensedBold-full.woff2") format('woff2');
}
@font-face {
  font-family: '72';
  src: url("../assets/font/72-Italic-full.woff2") format('woff2');
}
@font-face {
  font-family: '72';
  src: url("../assets/font/72-Light-full.woff2") format('woff2');
}
@font-face {
  font-family: '72';
  src: url("../assets/font/72-Regular-full.woff2") format('woff2');
}
@font-face {
  font-family: '72';
  src: url("../assets/font/72-Semibold-full.woff2") format('woff2');
}
@font-face {
  font-family: '72';
  src: url("../assets/font/72-SemiboldDuplex-full.woff2") format('woff2');
}
