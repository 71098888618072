@import url('https://fonts.googleapis.com/css?family=Open+Sans:light,lightitalic,regular,regulartalic,bold,bolditalic');
@import "../src/utils/72font.css";
body {
  margin: 0;
  font-family: '72', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/**** ICONS ****/
.symbol {
  display: inline-block;
  height: 21px;
  width: 21px;
  min-width: 21px;
  background-image: url('./assets/ico-symbols.png');
  background-repeat: no-repeat;
}
.symbol.NORMAL,
.symbol.Normal,
.symbol.normal {
  background-position: 0 -5px;
}
.symbol.DISRUPTION,
.symbol.disruption {
  background-position: -28px -5px;
}
.symbol.disruption-header {
  background-position: -116px -31px;
}
.symbol.DEGRADATION,
.symbol.degradation  {
  background-position: -56px -5px;
}
.symbol.degradation-header  {
  background-position: -78px -31px;
}

.clickable {
  cursor: pointer;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 4px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}