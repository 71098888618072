@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";


/**** SCSS VARIABLES ***/
$sap-gold         : #F0AB00;
$sap-black        : #000000;
$sap-white        : #ffffff;
$light-gray       : #CCCCCC;
$medium-gray      : #999999;
$dark-gray        : #666666;
$secondary-blue   : #008FD3;
$secondary-purple : #970A82;
$secondary-orange : #E35500;
$secondary-green  : #4Fb81C;
$incident-red     : #ED1c24;
$incident-yellow  : #ffc400;
$drop-shadow      : 0px 10px 6px -6px #eeeeee;
$container-y-padding : 2vw;

// Needed for the creation of css vars
// e.g. var(--sap-gold) would equal $sap-gold which equals #F0AB00
$export-list:(
 sap-gold            : $sap-gold,
 sap-black           : $sap-black,
 sap-white           : $sap-white,
 light-gray          : $light-gray,
 medium-gray         : $medium-gray,
 dark-gray           : $dark-gray,
 secondary-blue      : $secondary-blue,
 secondary-purple    : $secondary-purple,
 secondary-orange    : $secondary-orange,
 secondary-green     : $secondary-green,
 incident-red        : $incident-red,
 incident-yellow     : $incident-yellow,
 drop-shadow         : $drop-shadow,
 container-y-padding : $container-y-padding,
);

/*** BOOTSTRAP ****/
//Bootstrap overrides
$primary   : $secondary-blue;
$info      : $sap-gold;
$success   : $secondary-green;
$danger    : $secondary-orange;
$secondary : $light-gray;

//Bootstrap
@import "~bootstrap/scss/bootstrap";

/**** SCSS VARIABLES TO CSS VARIABLES ***/
// Defines a custom property
@mixin define-custom-property($name, $value) {
    #{"--" + $name}: $value;
}

body {
  @each $key,$val in $export-list{
      // Append SASS variables which are desired to be accessible:
      @include define-custom-property($key, $val);
    }
}


/**** COMMON APP WIDE STYLING ***/
a {
  color: $secondary-blue;
  text-decoration: none;
}

p {
  margin-bottom: 1.25rem;
}

h2, h3, h4, h5, h6 {
  font-size: 1.125rem;
  color: $dark-gray;
  font-weight: 700 !important;
}

.clickable {
  cursor: pointer;
}

h1 {
  font-size: 1.25rem;
  font-weight: 700 !important;
  color: $sap-black;
}

table {
  border-spacing: 0;
  border-top: None;
  width: 100%;
  margin-bottom: 20px;
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  background: #ffffff ;
  margin-top: 5px !important;
  border-top: none;
  padding: 10px 0;
  white-space: nowrap;
}
 
th:nth-child(2){
  background: rgb(229, 246, 253) !important;
}

.nopadding {
  padding: 0;
  margin: 0;
}

th:first-child {
  text-align: left !important;
  position: sticky;
  left: 0;
  top: 0;
  background-color: white;
}

td:first-child {
  text-align: left;
  position: sticky;
  left: 0;
  top: 0;
  background-color: white;
}

th.status-history-th:first-child {
  width: 25%;
}

td.status-history-td:first-child {
  width: 25%;
}

th.p2-th {
  min-width: 140px;
  width: 6vw;
}

td.p2-td {
  min-width: 140px;
  width: 6vw;
}

th.p2-th:last-child {
  min-width: 200px;
}

td.p2-td:last-child {
  min-width: 200px;
}

.scrollable {
  overflow: scroll;
}

td.status-history-td .servicelabel {
min-width: 160px;
text-align: left;
}
    
th.status-history-th,
td.status-history-td {
  margin: 0;
  padding: 0.5rem;
  text-align: center;
}

.table th, .table td {
  padding: 0.3rem;
}

.boxShadow {
  box-shadow: $drop-shadow;
}

.login-button{
  width: 25vw;
}

.about-modal{
  font-size: 15px;
}

.arrow {
  border: solid $secondary-blue;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
}

.non-sticky {
  position: unset !important;
}

// .p2-cell {
//   min-width: 6vw;
// }


.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.pull-right{
  float: right;
}

.login-button-container{
  display:block;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.hr {
  border-top :  '2px dashed black';
  // color     :  'transparent';
}

.alertBody {
  font-size: 12px ;
  width: 24vw ;
  margin-right: 1vw ;
  cursor: pointer ;
}

.tab-content { 
  width: 100%;
  max-height: 50vh;
  overflow: scroll;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.banner-font{
  font-size: inherit;
}

.tabletab {
  width: fit-content;
}
.nav-tabs {
  border-bottom: 1px solid #ddd;
}

.nav-link.active{
  border-bottom-color: transparent;
}

.image-container{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 35vw
}

.nav-tabs>li.active>a, .nav-tabs>li.active>a:hover, .nav-tabs>li.active>a:focus {
  color: #555;
  background-color: #fff;
  border: 1px solid #ddd;
  cursor: default;
}
// ****** MEDIA QUERIES ****** //

@media (min-width: 100px) {
  .about-modal{
    width: 90%;
    margin-top: .15rem;
  }

}

@media (min-width: 576px) {
  td .servicelabel {
    width: 200px;
   }
  .about-modal{
    width: 90%;
  }
  h1 {
    font-size: 1.5rem;
  }
  h2,h3,h4,h5,h6 {
    font-size: 1.4rem;
  }
}

@media (min-width: 768px) {
  td.status-history-td .servicelabel {
    width: 260px;
   }
  .about-modal{
    width: 80%;
  }
  h1{
    font-size: 1.5rem;
  }
  h2,h3,h4,h5,h6 {
    font-size: 1.45rem;
  }
  .login-button{
    float:right;
    width:25vw;
  }
}

@media (min-width: 992px) {
  td.status-history-td .servicelabel {
    width: 300px;
   }
  .about-modal{
    width: 900px;
  }
  h1{
    font-size: 2rem;
  }
  h2,h3,h4,h5,h6 {
    font-size: 1.5rem;
  }
}

@media (min-width: 1200px) {
  td.status-history-td .servicelabel {
    max-width: 300px !important;
   }
  h1{
    font-size: 2.2rem;
  }
  h2,h3,h4,h5,h6 {
    font-size: 1.55rem;
  }
}

@media screen and (max-width: 1350px) {


}

@media screen and (max-width: 780px) {
  td.status-history-td, th {
      font-size: .85em;
  }
  .tabclass{
      font-size: 12px;
  }
  .login-button{
    width: 98vw;
    margin: 0 auto;
    margin-bottom:5px;
  }
  .login-button-container{
    display:flex;
  }
  .alertBody{
    width: 48vw ;
  }
  .banner-font{
    font-size: .8rem;
  }
  .tab-content { 
    max-height: 40vh;
  }
}
@media screen and (max-width: 1024px){
  .image-container{
    width: 50vw
  }
}
@media screen and (max-width: 768px){
  .image-container{
    width: 65vw
  }
}

@media screen and (max-width: 480px) {
  .servicelabel{
      padding-left: 5px;
  }
  .alertBody{
    width: 98vw !important;
    margin: 0 auto;
  }
  .banner-font{
    font-size: .6rem;
  }
  .tab-content { 
    max-height: 30vh;
  }
  .image-container{
    width: 80vw
  }
}
@media screen and (max-width: 400px){
  .banner-font{
    font-size: .6rem;
  }
}


//IE11 styles
//Cant use vars defined above. Create css fallback classes 
.box-shadow{
  box-shadow: 0px 10px 6px -6px #eeeeee;
}

.sap-white{
  background: #ffffff;
}

.sap-black{
  background: #000;
}

.light-gray{
  background-color: #CCCCCC;
}

.container-y-padding{
  padding-left: 2vw;
  padding-right: 2vw;
}

.incident-red{
  color: #ED1c24;
}

.question{
  fill: #008FD3;
  color: #ffffff;
}



